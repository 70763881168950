@import "../../custom.scss";

.About {
    display: flex;
    flex-flow: wrap;
    margin: auto;
    padding: 0 60px;
    max-width: 900px;
    text-align: justify;
    line-height: 1.6;
    
}

.About hr {
    border: 1px solid $secon;
    width: 80%;
}

.About h1 {
    color: $dang;
    margin-top: 8px;
}

.About span {
    font-weight: bold;
    font-size: 18px;
    color: $dang;
}

.About a {
    color: white;
    font-weight: bold;
    text-decoration: none;
    text-shadow: 1px 1px 2px $prima;
}

.About a:hover {
    color: $prima;
    text-shadow: 1px 1px 2px white;
}

.About b {
    color: white;
    text-shadow: 1px 1px 2px $prima;
}

.About p {
    color: $secTxt;
}

.About img {
    float: left;
    margin-top: 16px;
    margin-bottom: 2px;
    margin-right: 12px;
    box-shadow: 1px 2px 3px #333;
}

.Top {
    background-color: $secon;
    border-radius: 12px;
    padding: 12px 32px;
    margin-bottom: 32px;
}

.Bottom {
    display: flex;
    flex-wrap: nowrap;
    margin: auto;
    margin-top: 16px;
    padding: 16px 60px;
    max-width: 800px;
    gap: 32px;
    margin-bottom: 24px;
}

.Bottom h3 {
    text-align: right;
    color: $dang;
    margin-top: 0;
}

.Right {
    flex: 50%;
}

.Left {
    flex: 50%;
}

@media (max-width: 1005px) {
    .Bottom{
        flex-wrap: wrap;
        padding: 0;
    }
    .Right .Left {
        flex: 1 100%;
    }
    .Right {
        margin-top: -32px;
    }
    .Bottom h3 {
        text-align: center;
    }
}

@media (max-width: 605px) {
    .Top {
        padding: 6px 16px;
    }
    .About {
        padding: 0 20px;
        text-align: left;
    }

    .About h1 {
        margin-top: -8px;
    }
    
    .About img {
        float: left;
        margin-bottom: 2px;
        margin-right: 10px;
    }
}