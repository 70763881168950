@import "../../custom.scss";

.Youtube li {
    background-color: rgba(196, 217, 176, 0.3);
    margin: auto;
    width: 80%;
    max-width: 1000px;
    font-size: 14px;
    display: flex;
    flex-flow: column;
    margin-bottom: 24px;
    border-radius: 24px;
    // box-shadow: 2px 3px 5px #999;
    // border: 4px solid rgba(75, 166, 88, 0.4);
    border: 4px solid $secon;
}

@media (max-width: 605px) {
    .Youtube li {
        width: 92%;
        font-size: 12px;
    }
}