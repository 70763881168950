// @import "../../custom.scss"

.Footer {
    display: flex;  
    flex-flow: row nowrap;
    order: 3;
    flex-grow: 1;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), #666);
    max-width: 1440px;
    min-height: 130px;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-end;
    // align-content: stretch;
    bottom: 0;
}

.Footer > * {
    padding: 10px;
    flex: 3 100%
}

.Left {
   width: 288px
}

.Right {
    display: flex;
    width: 288px;
    align-items: baseline;
    justify-content: flex-end;
    margin-right: 70px;
}

.Center {
    text-align: left;
    width: 60%;
}

@media (max-width: 605px) {
    .Footer { 
        flex: 3 100%; 
        flex-flow: wrap;
    }
    .Left   { 
        order: 1;
     }
    .Center { order: 2; }
    .Right  { 
        order: 3;
        margin-right: 0px;
        
        justify-content: center;
    }
}