@import "../../custom.scss";

.NavigationItems {
    margin: auto;
    margin-top: -56px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
}

@media (max-width: 605px) {
    .NavigationItems {
        flex-flow: column;
        margin-top: 60px;
        margin-left: 20%;
        gap: 24px;
    }
}