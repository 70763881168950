// .Master {
//     display: grid;
// }

.HeadComp {
    // box-shadow: 0 1px 3px #999;
    display: flex;
    width: 100%;
    margin: auto;
    background-position: center;
    position: fixed;
    top: 0;
    // align-items: left;
    z-index: 100;
}

// .HeadComp {
//     margin: auto;
//     background-position: center;

// }

.HeadComp img {
    position: static;
    padding-left: 16px;
    height: 147px;
    min-width: 240px;
    cursor: default;
}

.OverAll {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    box-shadow: 0px 1px 3px #999;
}



@media (max-width: 604px) {
    .DesktopOnly {
        display: none;
    }
}
