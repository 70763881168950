.Container {
    display: flex;
    flex-direction: row;
}

.Social a{
    padding: 6px;
    padding: 0 2px;
    filter: invert(1);
}

.Subscribe a {
    filter: invert(0);
}
.Subscribe a:hover {
    filter: invert(1);
}

.Social a:hover {
    filter: invert(0);
}