@import "../../custom.scss";


.Title {
    text-align: center !important;
    color: $post;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.Button {
    border-radius: 8px;
    border: none;
    background-color: $prim;
    color: white;
    padding: 8px 12px;
    margin-top: 24px;
}

.Button:hover {
    background-image: linear-gradient(to bottom, $prim, rgb(5, 59, 131));
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin:auto;
    width: 80%;
    max-width: 584px;
    border-radius: 25px;
    background: $secon;
    padding: 20px;
    margin-top: 12px;
}

.flexitemleft {
    flex: 25%;
    text-align: center;
    width: 300px;
    border-right: 2px solid white;
    height: 100%;
    padding-right: 20px;
    display: inline-block;
}

.flexitemleft input {
    position: relative;
    border: 1px solid #ccc;
    padding: 6px 16px;
    margin: 2px 0;
    border-radius: 4px;
    box-sizing: border-box;
}

.flexItemRight {
    flex: 25%;
    text-align: center;
    color: $post;
    width: 300px;
    white-space: nowrap;
    padding-top: 24px;
    margin: auto;
    display: inline-block;
}

.numpar {
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 2px 2px 1px $post;
}

.linkY {
    white-space: nowrap;
    color: $prim;
    text-decoration: none;
}

.linkY:hover {
    color: $warni;
}

.footNote {
    flex: 1 0 300px;
    flex-grow: 1;
    margin-top: 16px;
    text-align: center;
    color: $prima;
}

.Forma div {
    margin-top: 12px;
}

.Forma label {
    color: $post;
    text-shadow: 1px 1px 2px white;
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: opx;
}

hr {
    width: 80%;
    margin-top: 24px;
    margin-bottom: 24px;
}

.tutorial {
    margin: auto;
    display: flex;
    flex-flow: column;
}

.helpTip {
    position: absolute;
    display: inline-block;
    margin-left: 6px;
    margin-top: 0!important;
    text-align: center;
    background-color: rgba(255,255,255,0.3);
    border-radius: 50%;
    width: 16px;
    height: 0;
    font-size: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 0;
    cursor: default;
}

.helpTip:before{
    content:'?';
    font-weight: bold;
    color:$prima;
}

.helpTip:hover p{
    display:block;
    transform-origin: 100% 0%;

    -webkit-animation: fadeIn 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;

}

.helpTip p{    /* The tooltip */
    display: none;
    text-align: left;
    background-color: white;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    min-width: 175px;
    position: absolute;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    right: -4px;
    color: $dang;
    font-size: 13px;
    line-height: 1.4;
    z-index: 200;
}

.helpTip p:before{ /* The pointer of the tooltip */
    position: absolute;
    content: '';
    width:0;
    height: 0;
    border:6px solid transparent;
    border-bottom-color:$prim;
    right:4px;
    top:-12px;
}

.helpTip p:after{ /* Prevents the tooltip from being hidden */
    width:100%;
    height:40px;
    content:'';
    position: absolute;
    top:-40px;
    left:0;
}

@-webkit-keyframes fadeIn {
    0% { 
        opacity:0; 
        transform: scale(0.6);
    }

    100% {
        opacity:100%;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:100%; }
}

@media (max-width: 600px) {
    .flexContainer {
        display: flex;
        flex-wrap: wrap;
    }

    .flexitemleft, .flexItemRight {
        flex: 100%;
    }

    .flexItemRight {
        text-align: center;
        margin: auto;
        padding: 10px;
        padding-top: 10px;
        white-space: nowrap;
        position: relative;
    }
    
    .flexitemleft {
        text-align: center;
        margin: auto;
        border-right: 0px;
        padding-right: 0px;
    }
}