@import "../../../custom.scss";

.description {
    text-align: justify!important;
    margin: auto;
    margin-top: 12px;
    margin-bottom: 24px;
    padding: 16px;
    max-width: 600px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 2px 3px 5px #666;
}

.pageLayout {
    margin: auto;
    width: 90%;
    max-width: 600px;
}

.vTitle {
    color: $dang;
    font-weight: bold;
    font-size: 24px;
    padding: 8px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: 16px;
    padding-bottom: 24px;
}

.videoContainer {
    margin-top: 24px;
    margin-bottom: 12px;
    position:relative;
    padding-bottom:56.25%;
    height:0;
    overflow:hidden;
    // box-shadow: 2px 3px 5px #666;
}

.videoContainer iframe,.videoContainer object,.videoContainer embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%
}

@media (max-width: 605px) {
    .description {
        width: 85%;
    }
    .pageLayout {
        width: 95%;
    }
}