@import "../../../../custom.scss";

.BurgerButton {
    display: flex;
    flex-flow: column;
    position: fixed;
    z-index: 250;
    top: 12px;
    right: 12px;
    transform-origin: 1px;
    transition: all 0.3s linear;
}

.Burg1 {
    width: 2rem;
    height: 0.25rem;
    margin-bottom: 8px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px #666;
}

.Burg2 {
    width: 2rem;
    height: 0.25rem;
    margin-bottom: 8px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px #666;
}

.Burg3 {
    width: 2rem;
    height: 0.25rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px #666;
}

@media (min-width: 604px) {
    .BurgerButton {
        display: none;
    }
}