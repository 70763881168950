@import "../../custom.scss";

.Store {
    display: flex;
    flex-flow: column nowrap;
    margin: auto;
    max-width: 900px;
    text-align: center;
    justify-content: center;
}

.Store h3 {
    color: $dang;
    margin-bottom: -16px;
}

.Store ul {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
}

.Store li {
    margin: 24px;
    align-self: flex-end;
    // border: 1px solid black;
}

.names {
    margin-top: 24px;
    color: $prima;
}

.mention {
    font-size: 9px;
    color: #999;
}