@import "../../../custom.scss";

.NavigationItem {
    margin: 10px 0;
    font-weight: bold;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a {
    color: $prima; 
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: #40a4c8;
}

@media (min-width: 604px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavigationItem a {
        color: white;
        font-weight: bold;
        text-shadow: 2px 2px $post;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        // background-color: $secon;
        border-bottom: 4px solid $prima;
        color: white;
        background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(255,255,255,0.5));
    }
}